<template>
  <div class="login-form">
    <form class="form" @submit.prevent="submit">
      <div class="pb-13 pt-lg-0 pt-5">
        <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
          Esqueceu a senha?
        </h3>
        <p class="text-muted font-weight-bold font-size-h4">
          Informe seu email para redefinir sua senha
        </p>
      </div>
      <div class="form-group">
        <input
          class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
          type="email"
          placeholder="Email"
          autocomplete="off"
          v-model="form.email"
          required
        />
      </div>
      <div class="form-group d-flex flex-wrap pb-lg-0">
        <button
          type="submit"
          class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
          :disabled="loading"
          :class="{
            spinner: loading,
            'spinner-light': loading,
            'spinner-right': loading
          }"
        >
          Enviar
        </button>
        <b-btn
          class="font-weight-bolder font-size-h6 px-8 py-4 my-3"
          variant="light-primary"
          :to="{ name: 'login' }"
        >
          Cancelar
        </b-btn>
      </div>
    </form>
  </div>
</template>

<script>
import { PURGE_AUTH, RECOVER } from "@/core/store/auth.module";

export default {
  name: "Recover",
  data() {
    return {
      loading: false,
      form: {
        email: ""
      }
    };
  },
  methods: {
    submit() {
      if (!this.loading) {
        // clear existing errors
        this.$store.commit(PURGE_AUTH);

        // set spinner to submit button
        this.loading = true;

        // send recover request
        this.$store
          .dispatch(RECOVER, this.form)
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    }
  }
};
</script>
